.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
    color: white;
    text-decoration: none;
}
.container svg {
    height: 2.5em;
    width: 2.5em;
}
.vert {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
}
.vert span {
    font-size: 1.6em;
    font-weight: 300;
}
div.iconBox {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
}

@media (max-width: 480px) {
    .container {
        height: 30vh;
    }
}