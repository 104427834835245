.header {
    position: fixed;
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: black;
}

.dummy {
    height: 10vh;
}

.logoContainer {
    align-self: flex-start;
    border-radius: 0 0 1vw 1vw;
}

.dropdown.close {
    color: black;
}

.swapContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerLogoContainer {
    height: 10vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.innerLogoContainer img {
    margin-left: 1vw;
    margin-right: 1vw;
    max-width: 15vw;
    height: auto;
    max-height: 8vh;
}

.header ul {
    display: inline-block;
    list-style: none;
}

.dropdown {
    font-size: min(2.5vw, 32pt);
    cursor: pointer;
}

.header > ul li {
    display: inline-block;
    margin-left: 5vw;
    font-weight: 400;
    font-size: min(2.3vw, 30px);
}

.header > ul li a {
    text-decoration: none;
    color: white;
}

@media (max-width: 480px) {
    .dropdown {
        font-size: 2em;
    }
    .innerLogoContainer img {
        max-width: 25vw;
        max-height: 10vh;
    }
    .header > ul {
        padding: 0;
        margin: 0;
    }
    .header > ul li {
        font-size: 1.4em;
        display: block;
        margin: 1vh 0;
    }
    .logoContainer {
        align-self: inherit;
    }
    .header {
        height: auto;
    }
}