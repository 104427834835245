.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10vh 10vw 30vh 10vw;
}

/*todo mobile styling : stack*/
.sprite {
    max-width: 35vw;
    max-height: 35vw;
    margin: 0 5vw;
}

.textContainer {
    height: min-content;
}

.textContainer p {
    font-weight: 300;
}

.textContainer a {
    text-decoration: underline;
    color: #888;
    transition: color .3s ease-out;
}

.textContainer a:hover {
    color: white;
}

@media (max-width: 480px) {
    .container {
        flex-direction: column !important;
        margin: 0 10vw 10vh;
    }

    .textContainer {
        text-align: center !important;
    }

    .sprite {
        max-width: 45vw;
        max-height: 45vw;
    }
}