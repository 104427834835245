.parent {
    display: flex;
    justify-content: center;
}

.policy {
    max-width: 1000px;
    margin: 5vh 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}