@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body, #root {
    font-family: Roboto, sans-serif;
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    user-select: none;
}

body {
    background-color: black;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: #000;
}

::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
}