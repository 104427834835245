.scrollEncourage {
    position: fixed;
    left: 50%;
    top: 80vh;
    transition: opacity 500ms;
}

.scrollEncourage::before {
    content: " ";
    position: absolute;
    border: white solid 1px;
    border-radius: 1px;
    left: -1px;
    animation: scrollEncourageKeyframes infinite 1750ms;
    border-bottom: none;
    border-top: none;
}

@keyframes scrollEncourageKeyframes {
    0% {
        opacity: 50%;
        height: 5px;
        top: 0;
    }
    20% {
        opacity: 100%;
        height: 30px;
        top: 15px;
    }
    100% {
        opacity: 50%;
        height: 5px;
        top: 0;
    }
}