.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container h5 {
    width: 80vw;
    font-size: 24px;
    font-weight: 200;
    border-bottom: .5px solid #fff;
    padding-bottom: 5px;
}