.footer {
    margin: 3%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.legal {
    text-align: center;
    font-size: .8rem;
}

.legal p {
    margin: .5rem;
}

.divider {
    width: 80vw;
    border-width: .5px;
}

.legal a {
    text-decoration: underline;
    color: #888;
    transition: color .3s ease-out;
}