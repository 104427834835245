h1.title {
    font-weight: 300;
    letter-spacing: .2em;
    text-transform: uppercase;
    text-align: center;
}
div.selfMarginContainer {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
}

@media (max-width: 480px) {
    h1.title {
        margin: 0 5vw;
        font-size: 1.8em;
    }
}
