.logoSplash, .secondSplash, .thirdSplash {
    width: min(70%, 750px);
    grid-row: 1;
    grid-column: 1;
    margin-bottom: 15vh;
}

.container {
    position: absolute;
    min-height: 100vh;
    top: 0;
    width: 100%;
    display: grid;
    justify-items: center;
    align-content: center;
    grid-template-columns: 100%;
}

.secondSplash, .thirdSplash {
    display: grid;
    justify-items: center;
    align-items: center;
}

.secondSplash > *, .thirdSplash > * {
    padding-top: 1px;
    padding-bottom: 1px;
    grid-row: 1;
    grid-column: 1;
}

.secondSplash h2, .thirdSplash h2 {
    font-size: min(5vw, 50pt);
}

.secondSplash h2 {
    color: gray;
}

.thirdSplash h2 {
    color: black;
}

.coverContainer {
    position: absolute;
    min-height: 100vh;
    top: 0;
    width: 100%;
}

.navCover {
    height: 10vh;
    display: flex;
    align-items: center;
    position: absolute;
}

.imageCover {
    max-width: 15vw;
    height: auto;
    max-height: 8vh;
}