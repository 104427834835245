.siteSelector {
    top: 10vh;
    background: none;
}

.siteSelector ul {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.siteSelectorOption {
    color: black;
    margin-left: 0;
    padding: 5%;
    font-weight: 300;
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.siteSelectorOption a {
    text-decoration: none;
    color: black;
}